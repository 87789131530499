import React from "react"
import {graphql, Link} from 'gatsby'
import LayoutSimple from "../components/layout-simple";
import get from 'lodash/get'
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Card, {CardSection, CardSectionHeader, CardHeader, CardSectionContent} from "@kiwicom/orbit-components/lib/Card";
import Text from "@kiwicom/orbit-components/lib/Text";
import Seperator from "@kiwicom/orbit-components/lib/Separator";
import Grid from "styled-components-grid";
import Dex from "../components/dex";
import Nel from "../components/nel";
import Molly from "../components/molly";
import Bonnie from "../components/bonnie";
import Img from "gatsby-image"
import tokens from '../tokens'
import styled from "styled-components";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from './questions-about-owning-a-labradoodle.module.css'
import blob from '../blob/blob-shape_orange_b.svg';

const FAQPage = ({data}) => {


  const dexImage = get(data, 'dexImage.childImageSharp.fluid')
  const nelImage = get(data, 'nelImage.childImageSharp.fluid')
  const indieImage = get(data, 'indieImage.childImageSharp.fluid')
  const bonnieImage = get(data, 'bonnieImage.childImageSharp.fluid')
  const mollyImage = get(data, 'mollyImage.childImageSharp.fluid')
  const faqs = get(data, 'allContentfulFaqQuestion.edges')
  const options = {
    renderMark: {
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => { node.data.target.fields && <img className={styles.faqEmbed}  src={node.data.target.fields.file['en-US'].url} />},
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  const ImgRounded = styled(Img)`
      border-radius: 10px;
    `;

  const GridExtended = styled(Grid)`
      padding: 0px;
      padding-top: 20px;
      margin-bottom: 50px;
      > div {
      margin-bottom: 50px;
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 70px;
        color: white;
        font-weight: bold;
        figure {
          position: relative;
        }
         transition: all 0.3s ease;
        figcaption {
          margin: 0 -20px;
          padding: 20px;
          background: rgba(0,0,0,0.2);
          position: absolute;
          bottom: -95px;
          left: 0px;
          right: 0px;
        }
        a {
           color: white !important;
        }
        &:first-child {
           transform: rotate(-2deg); 
           a { 
            background: ${tokens.brand('nel')}; !important;
           }
           background: ${tokens.brand('nel')};
        }
        &:nth-child(2n) {
            transform: rotate(-5deg);
            a { 
                background: ${tokens.brand('indie')}; !important; 
            }
            background: ${tokens.brand('indie')};
        }
        &:nth-child(3n)  {
         a { 
                background: ${tokens.brand('bonnie')}; !important; 
            }
            transform: rotate(3deg)  translateY(20px);
             background: ${tokens.brand('bonnie')};
        }
        &:nth-child(4n) {
         a { 
                background: ${tokens.brand('molly')}; !important; 
            }
            transform: rotate(-7deg) translateY(10px);;
             background: ${tokens.brand('molly')};
        }
        &:nth-child(5n) {
          a {
            background: ${tokens.brand('dex')}; !important;
          }
          transform: rotate(-7deg) translateY(10px);;
          background: ${tokens.brand('dex')};
        }
        &:hover {
          &:first-child {
           transform: rotate(-3deg);
          }
          &:nth-child(2n) {
              transform: rotate(-4deg);
            
          }
          &:nth-child(3n)  {
           transform: rotate(2deg) translateY(20px);
              
          }
          &:nth-child(4n) {
              transform: rotate(-6deg) translateY(10px);
          }
        }
           
      }
    `;

  const Polaroid = styled(Link)`
    `;

  const GridUnit = styled(Grid)`
      padding: 20px;
      
    `


  return (
      <LayoutSimple location="questions-about-owning-a-labradoodle">
        <main>
          <h1>FAQ</h1>
          <img className={'blob-top-right'} src={blob} />
          <div
              dangerouslySetInnerHTML={{
                __html: data.contentfulTextSnippet.text.childMarkdownRemark.html,
              }}>
          </div>

          <GridExtended>

            <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
              <Polaroid  to="/gallery/Nel-golden-doodle" style={{margin: 5 + 'px'}}>
                <figure className="">
                  <ImgRounded
                      fluid={nelImage}

                      className="kg-image"
                  />
                  <figcaption><span>Nel</span> is our latest addition to our family. She's a  Golden Doodle and has a dense fleecy coat.
                  </figcaption>
                </figure>
              </Polaroid>
            </Grid.Unit>

            <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
              <Polaroid  to="/gallery/Indie-from-Puppy" style={{margin: 5 + 'px'}}>
                <figure className="">
                  <ImgRounded
                      fluid={indieImage}

                      className="kg-image"
                  />
                  <figcaption><span>Indie</span> is an UltimateDoodle (Double Doodle) and daughter of Kingscotedoodle matriarch, Molly.
                  </figcaption>
                </figure>
              </Polaroid>
            </Grid.Unit>


            <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
              <Polaroid to="/gallery/Bonnie-Labradoodle" style={{padding: 5 + 'px'}}>
                <figure className="">
                  <ImgRounded
                      fluid={bonnieImage}
                      className="kg-image"
                  />
                  <figcaption><span >Bonnie</span> our medium sized, 'Scruffy' coated Labradoodle.
                  </figcaption>
                </figure>

              </Polaroid>
            </Grid.Unit>
            
            <Grid.Unit  size={{tablet: 1 / 2, desktop: 1 / 5}}>
              <Polaroid to="/gallery/Molly-labradoodle" style={{margin: 5 + 'px'}}>
                <figure className="">
                  <ImgRounded
                      fluid={mollyImage}
                      className="kg-image"
                  />
                  <figcaption><span >Molly</span> Our medium sized, curly coated Labradoodle.
                  </figcaption>
                </figure>
              </Polaroid>
            </Grid.Unit>
            


            <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
              <Polaroid to="/gallery/dexter-gallery" style={{margin: 5 + 'px'}}>
                <figure className="">
                  <ImgRounded
                      fluid={dexImage}
                      className="kg-image"
                  />
                  <figcaption><span >Dex</span> Our Border Collie. He rounds up our Doodles!
                  </figcaption>
                </figure>
              </Polaroid>
            </Grid.Unit>

          </GridExtended>





        </main>

        <Heading>Frequently Asked Questions about Owning a Doodle</Heading>
        <Seperator/>
        <Card>

          {faqs.map(({ node }) => {

            return (
                <CardSection key={node.id} expandable>
                  <CardSectionHeader>
                    <Heading type="title3" element="h3">
                      { node.headerTitle }
                    </Heading>
                  </CardSectionHeader>
                  <CardSectionContent>
                    <Text>
                      { documentToReactComponents(node.faqContent.json, options) }
                    </Text>
                  </CardSectionContent>
                </CardSection>
            )
          })}

        </Card>


      </LayoutSimple>
  )
}
export default FAQPage


export const pageQuery = graphql`
  query FAQQuery {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulFaqQuestion {
      edges {
        node {
          id
          headerTitle
          headerContent {
            id
            childMarkdownRemark {
              id
              rawMarkdownBody
            }
          }
          expandable
          faqContent {
            id
            json
          }
        }
      }
    }
    
    
    contentfulTextSnippet(slug: {eq: "faq-intro"}) {
      id
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
    }

    dexImage: file( relativePath: {eq: "dex-border-collie-in-wheat-field.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mollyImage: file( relativePath: {eq: "molly-with-labradoodle-puppy.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    indieImage: file( relativePath: {eq: "indie-labradoodle-looking.jpeg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nelImage: file( relativePath: {eq: "nel-6-month-old-labradoodle.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bonnieImage: file( relativePath: {eq: "bonnie-labdraoodle-dog-kingscote.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroImage: file(  relativePath: {eq: "kingscote-doodle-sleeping-puppy-nel.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
